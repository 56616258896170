<template>
  <div>
    <click-to-edit
      v-if="scope.row.scale"
      v-model="scope.row.scale[targetIndex]"
      type="textarea"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.currentScalePart.'+targetIndex)"
      placeholder=""
      @input="saveElementPropChange(scope.row, 'scale', {['currentScalePart.'+targetIndex]:$event})"
      :wordLimit="2000"
      :maxVisibleLines="3"
    >
    </click-to-edit>
    <div v-else style="color: #b1b1b2;">Сначала необходимо выбрать шкалу</div>
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-scale-pay-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {
    targetIndex: {type: String, required: true}
  },
  data() {
    return {}
  },

  methods: {}
}

</script>

<style scoped lang="scss">

</style>
